import React, { useState } from 'react'
import md5 from 'md5'
import axios from 'axios'

const EmailAccountList = ({ accounts, onReAuthorization, onDeleteAccount }) => {
	const [checkingStatus, setCheckingStatus] = useState({})
	const [watchStatus, setWatchStatus] = useState({})

	const getAvatarUrl = account => {
		if (account.picture) {
			const cleanedPictureUrl = account.picture.split('=')[0]
			return cleanedPictureUrl
		}

		const emailHash = md5(account.email.toLowerCase().trim())
		const gravatarUrl = `https://www.gravatar.com/avatar/${emailHash}?d=mp&s=80`
		return gravatarUrl
	}

	const checkWatchStatus = async (accountId, email) => {
		try {
			setCheckingStatus(prev => ({ ...prev, [accountId]: true }))
			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			const response = await axios.get('/api/user/gmail/watch-status', {
				headers: {
					Authorization: token,
				},
			})
			setWatchStatus(prev => ({ ...prev, [accountId]: response.data }))
		} catch (error) {
			console.error('Error checking watch status:', error)
			setWatchStatus(prev => ({
				...prev,
				[accountId]: {
					hasWatch: false,
					message: error.response?.data?.message || 'Error checking status',
				},
			}))
		} finally {
			setCheckingStatus(prev => ({ ...prev, [accountId]: false }))
		}
	}

	return (
		<ul className="space-y-3">
			{accounts.map(account => (
				<li
					key={account._id}
					className="bg-[#263340] p-4 rounded-lg border border-[#374151] hover:border-[#4B5563] transition-all duration-200"
				>
					<div className="flex flex-col space-y-3">
						<div className="flex items-center justify-between">
							<div className="flex items-center space-x-3">
								<div className="w-10 h-10 rounded-full overflow-hidden bg-[#374151]">
									<img
										src={getAvatarUrl(account)}
										alt={account.email}
										className="w-full h-full object-cover"
										referrerPolicy="no-referrer"
										onError={e => {
											console.log('Image load error for:', account.email)
											e.target.onerror = null
											e.target.src = `https://www.gravatar.com/avatar/${md5(
												account.email.toLowerCase().trim()
											)}?d=mp&s=80`
										}}
									/>
								</div>
								<div>
									<span className="font-medium text-white">
										{account.email}
									</span>
									{account.provider === 'smtp' && (
										<span className="ml-2 text-sm text-gray-400">
											(SMTP: {account.host})
										</span>
									)}
								</div>
							</div>
							<div className="flex space-x-2">
								{account.canReadEmail && (
									<button
										onClick={() => checkWatchStatus(account._id, account.email)}
										disabled={checkingStatus[account._id]}
										className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 ${
											checkingStatus[account._id]
												? 'bg-gray-700 text-gray-400 cursor-not-allowed'
												: 'bg-blue-900/30 text-blue-400 hover:bg-blue-900/50'
										}`}
									>
										{checkingStatus[account._id]
											? 'Checking...'
											: 'Check Status'}
									</button>
								)}
								{(!account.canSendEmail || !account.canReadEmail) && (
									<button
										onClick={() =>
											onReAuthorization(account.provider, account.email)
										}
										className="text-red-400 bg-red-900/30 hover:bg-red-900/50 px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200"
									>
										Needs Re-authorization
									</button>
								)}
								<button
									onClick={() => onDeleteAccount(account._id)}
									className="text-gray-300 bg-[#374151] hover:bg-[#4B5563] px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200"
								>
									Revoke Access
								</button>
							</div>
						</div>
						{watchStatus[account._id] && (
							<div className="flex items-center space-x-2 text-sm">
								<div
									className={`w-2 h-2 rounded-full ${
										watchStatus[account._id].hasWatch
											? 'bg-green-500'
											: 'bg-red-500'
									}`}
								/>
								<span
									className={`${
										watchStatus[account._id].hasWatch
											? 'text-green-400'
											: 'text-red-400'
									}`}
								>
									{watchStatus[account._id].message}
								</span>
							</div>
						)}
						<div className="flex items-center space-x-4 text-sm">
							<div className="flex items-center space-x-2 text-gray-400">
								<svg
									className="w-4 h-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
									/>
								</svg>
								<span>
									Added {new Date(account.createdAt).toLocaleDateString()}
								</span>
							</div>
							<div className="flex items-center space-x-2">
								<div
									className={`w-2 h-2 rounded-full ${
										account.canSendEmail ? 'bg-green-500' : 'bg-red-500'
									}`}
								/>
								<span
									className={`${
										account.canSendEmail ? 'text-green-400' : 'text-red-400'
									}`}
								>
									Email Send {account.canSendEmail ? 'Enabled' : 'Disabled'}
								</span>
							</div>
							<div className="flex items-center space-x-2">
								<div
									className={`w-2 h-2 rounded-full ${
										account.canReadEmail ? 'bg-green-500' : 'bg-red-500'
									}`}
								/>
								<span
									className={`${
										account.canReadEmail ? 'text-green-400' : 'text-red-400'
									}`}
								>
									Email Read {account.canReadEmail ? 'Enabled' : 'Disabled'}
								</span>
							</div>
						</div>
					</div>
				</li>
			))}
		</ul>
	)
}

export default EmailAccountList
