import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

import ConnectEmailAccountModal from './ConnectEmailAccountModal'
import EmailAccountList from './EmailAccountList'

function EmailSettings() {
	const [templates, setTemplates] = useState([])
	const [accounts, setAccounts] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [isConnectModalOpen, setIsConnectModalOpen] = useState(false)
	const [isMaxAccountsModalOpen, setIsMaxAccountsModalOpen] = useState(false)

	const jwtToken = localStorage.getItem('jwtToken')
	const user = jwtToken ? jwtDecode(jwtToken) : null

	const fetchEmailData = async () => {
		try {
			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			const response = await axios.get('/api/email/templates', {
				headers: {
					Authorization: token,
				},
			})
			setTemplates(response.data.templates)
			setAccounts(response.data.accounts)
			setLoading(false)
		} catch (err) {
			setError('Error fetching email data')
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchEmailData()
	}, [])

	const handleEmailAuthorization = async (provider, smtpConfig = null) => {
		try {
			if (accounts.length >= 3) {
				setIsMaxAccountsModalOpen(true)
				return
			}

			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			if (provider === 'smtp') {
				const response = await axios.post(
					'/api/user/connect-smtp',
					smtpConfig,
					{
						headers: {
							Authorization: token,
						},
					}
				)
				if (response.data.success) {
					fetchEmailData()
					setIsConnectModalOpen(false)
				}
			} else {
				const response = await axios.post(
					'/api/user/request-email-access',
					{
						provider,
						includeReadAccess: smtpConfig?.includeReadAccess,
					},
					{
						headers: {
							Authorization: token,
						},
					}
				)

				if (response.data.authUrl) {
					window.location.href = response.data.authUrl
				}
			}
		} catch (error) {
			console.error('Error requesting email access:', error)
			if (error.response && error.response.status === 400) {
				setError(error.response.data.message)
			} else {
				setError('An error occurred while requesting email access')
			}
		}
	}

	const handleDeleteAccount = async accountId => {
		if (
			!window.confirm('Are you sure you want to delete this email account?')
		) {
			return
		}

		try {
			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			await axios.delete(`/api/user/email-account/${accountId}`, {
				headers: {
					Authorization: token,
				},
			})
			fetchEmailData() // Refresh the accounts list
		} catch (error) {
			console.error('Error deleting email account:', error)
			setError('An error occurred while deleting the email account')
		}
	}

	const handleReAuthorization = async (provider = 'gmail', email = null) => {
		try {
			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			const response = await axios.post(
				'/api/user/re-authorize-email',
				{ provider, email },
				{
					headers: {
						Authorization: token,
					},
				}
			)

			if (response.data.authUrl) {
				window.location.href = response.data.authUrl
			}
		} catch (error) {
			console.error('Error requesting email access:', error)
			if (error.response && error.response.status === 400) {
				setError(error.response.data.message)
			} else {
				setError('An error occurred while requesting email access')
			}
		}
	}

	const handleModalOpen = () => {
		if (accounts.length >= 3) {
			setIsMaxAccountsModalOpen(true)
			return
		}

		setIsConnectModalOpen(true)
	}

	if (loading) return <div className="text-white">Loading...</div>
	if (error) return <div className="text-red-500">{error}</div>

	return (
		<>
			<div className="text-white">
				<section className="mb-8">
					<h3 className="text-xl font-semibold mb-4">Email Accounts</h3>
					<EmailAccountList
						accounts={accounts}
						onReAuthorization={handleReAuthorization}
						onDeleteAccount={handleDeleteAccount}
					/>

					<button
						className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded"
						onClick={handleModalOpen}
					>
						Connect New Account
					</button>
				</section>
			</div>

			<ConnectEmailAccountModal
				isOpen={isConnectModalOpen}
				onClose={() => setIsConnectModalOpen(false)}
				onAuthorize={handleEmailAuthorization}
			/>

			{isMaxAccountsModalOpen && (
				<>
					<div
						className="fixed inset-0 bg-black transition-opacity duration-300 opacity-50 z-40"
						onClick={() => setIsMaxAccountsModalOpen(false)}
					></div>
					<div className="fixed inset-0 flex items-center justify-center -mt-32 z-50">
						<div className="bg-[#15202b] rounded-lg p-8 w-[32rem] h-[15rem] ring-1 ring-white/10 shadow-[0_0_15px_rgba(59,130,246,0.1)] relative">
							<div className="flex justify-between items-start">
								<h2 className="text-2xl font-bold text-white">
									Maximum Accounts Reached
								</h2>
								<button
									onClick={() => setIsMaxAccountsModalOpen(false)}
									className="text-gray-400 hover:text-gray-300 -mt-1"
								>
									<svg
										className="w-4 h-4"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M6 18L18 6M6 6l12 12"
										></path>
									</svg>
								</button>
							</div>
							<p className="mt-6 mb-6 text-gray-300 text-lg">
								You have reached the maximum limit of 3 email accounts. Please
								delete an existing account before adding a new one.
							</p>
							<div className="absolute bottom-8 right-8">
								<button
									onClick={() => setIsMaxAccountsModalOpen(false)}
									className="bg-[#263340] text-white px-5 py-2.5 rounded-lg hover:bg-[#2c3b4a] transition-colors duration-150 ease-in-out text-sm font-semibold"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default EmailSettings
