import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Bars3Icon, ChevronDownIcon } from '@heroicons/react/24/outline'
import Logo from '../../media/logo.svg'

import {
	CogIcon,
	ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline'

const truncate = (text, maxLength = 15) => {
	if (text.length <= maxLength + 3) return text

	return text.substr(0, maxLength - 3) + '...'
}

function AuthNavBar({ togglePanel }) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const dropdownRef = useRef(null)
	const navigate = useNavigate()

	const jwtToken = localStorage.getItem('jwtToken')
	const user = jwtToken ? JSON.parse(atob(jwtToken.split('.')[1])) : null

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleLogout = () => {
		localStorage.removeItem('jwtToken')
		navigate('/')
	}

	return (
		<nav className="bg-[#15202b] py-4">
			<div className="flex justify-between items-center px-4">
				<div className="flex items-center space-x-2 ml-4">
					<button
						onClick={togglePanel}
						className="text-white focus:outline-none"
					>
						<Bars3Icon className="h-6 w-6" />
					</button>
					<Link to="/dashboard" className="flex items-center">
						<img src={Logo} alt="LoadLine Logo" className="h-6 w-auto" />
						<span className="text-xl font-bold text-white ml-1">LoadLine</span>
					</Link>
				</div>
				<div className="relative mr-4" ref={dropdownRef}>
					<button
						onClick={() => setIsDropdownOpen(!isDropdownOpen)}
						className="flex items-center space-x-2 text-white hover:text-sky-400 transition duration-300"
					>
						<span className="font-medium text-sky-400">
							{truncate(`${user?.email}`, 22)}
						</span>
						<ChevronDownIcon className="h-4 w-4" />
					</button>
					{isDropdownOpen && (
						<div className="absolute right-0 mt-2 w-48 bg-[#15202b] rounded-md shadow-lg py-1 z-10 ring-1 ring-white/10 shadow-[0_0_10px_rgba(59,130,246,0.1)]">
							<Link
								to="/settings"
								className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-[#1c2834] hover:text-sky-400"
								onClick={() => setIsDropdownOpen(false)}
							>
								<CogIcon className="h-4 w-4 mr-2" />
								Settings
							</Link>
							<hr className="border-gray-600 mx-2" />
							<button
								onClick={handleLogout}
								className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-[#1c2834] hover:text-sky-400"
							>
								<ArrowRightStartOnRectangleIcon className="h-4 w-4 mr-2" />
								Logout
							</button>
						</div>
					)}
				</div>
			</div>
		</nav>
	)
}

export default AuthNavBar
