import React from 'react'

function Pagination({ currentPage, hasMore, handleSearch }) {
	const handlePageChange = newPage => {
		handleSearch(newPage)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<div className="border-t border-gray-700 w-full">
			<nav
				aria-label="Pagination"
				className="flex items-center justify-center px-4 py-3 sm:px-6"
			>
				<div className="flex items-center gap-3">
					<button
						onClick={() => handlePageChange(currentPage - 1)}
						disabled={currentPage === 1}
						className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold 
	${
		currentPage === 1
			? 'bg-gray-700 text-gray-400 cursor-not-allowed'
			: 'bg-[#263340] text-gray-200 ring-1 ring-gray-600 hover:bg-[#2c3b4a]'
	} transition-colors duration-150 ease-in-out`}
					>
						Previous
					</button>
					<span className="text-sm text-gray-400">Page {currentPage}</span>
					<button
						onClick={() => handlePageChange(currentPage + 1)}
						disabled={!hasMore}
						className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold
	${
		!hasMore
			? 'bg-gray-700 text-gray-400 cursor-not-allowed'
			: 'bg-[#263340] text-gray-200 ring-1 ring-gray-600 hover:bg-[#2c3b4a]'
	} transition-colors duration-150 ease-in-out`}
					>
						Next
					</button>
				</div>
			</nav>
		</div>
	)
}

export default Pagination
