import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SidePanel from '../SidePanel/SidePanel'
import AuthNavBar from './AuthNavBar'
import Pagination from './Pagination'
import BrokerPanel from './BrokerPanel'
import CarrierPanel from './CarrierPanel'
// import EmailAccessModal from '../EmailAccessModal/EmailAccessModalMain'
import BrokerFilters from './BrokerFilters'
import CarrierFilters from './CarrierFilters'
import Broker from './Broker'
import Carrier from './Carrier'
import SearchResultSkeleton from './SearchResultSkeleton'
import FilterBreadcrumbs from './FilterBreadcrumbs'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

function DashboardMain() {
	const [isPanelOpen, setIsPanelOpen] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const [searched, setSearched] = useState(false)
	const [activeTab, setActiveTab] = useState('brokers')
	const [activeStatusFilter, setActiveStatusFilter] = useState('active')

	// Separate filter states for brokers and carriers
	const [brokerFilters, setBrokerFilters] = useState({
		status: 'active',
		minAge: '',
		maxAge: '',
		bondAmount: '', // For future use
		authorityType: '', // For future use
		state: '', // For future use
		registrationStatus: '', // For future use
	})

	const [carrierFilters, setCarrierFilters] = useState({
		status: 'active',
		minAge: '',
		maxAge: '',
		equipmentType: '', // For future use
		insuranceStatus: '', // For future use
		safetyRating: '', // For future use
		operatingRadius: '', // For future use
		hazmatCertified: '', // For future use
	})

	const [selectedItem, setSelectedItem] = useState(null)
	const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false)
	const [showEmailAccessModal, setShowEmailAccessModal] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [hasMore, setHasMore] = useState(false)

	const togglePanel = () => {
		setIsPanelOpen(!isPanelOpen)
	}

	const openDetailPanel = item => {
		setSelectedItem(item)
		setIsDetailPanelOpen(true)
	}

	const handleFiltersChange = newFilters => {
		if (activeTab === 'brokers') {
			setBrokerFilters(prev => ({ ...prev, ...newFilters }))
			handleSearchWithFilters(1, { ...brokerFilters, ...newFilters })
		} else {
			setCarrierFilters(prev => ({ ...prev, ...newFilters }))
			handleSearchWithFilters(1, { ...carrierFilters, ...newFilters })
		}
	}

	const handleRemoveFilter = filterKey => {
		if (activeTab === 'brokers') {
			setBrokerFilters(prev => {
				const updated = { ...prev, [filterKey]: '' }
				handleSearchWithFilters(1, updated)
				return updated
			})
		} else {
			setCarrierFilters(prev => {
				const updated = { ...prev, [filterKey]: '' }
				handleSearchWithFilters(1, updated)
				return updated
			})
		}
	}

	const handleSearchWithFilters = async (
		page = 1,
		currentFilters = activeTab === 'brokers' ? brokerFilters : carrierFilters
	) => {
		try {
			setIsLoading(true)
			setError(null)
			setSearched(true)
			setCurrentPage(page)

			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			const endpoint =
				activeTab === 'carriers'
					? 'carriers/search-carrier'
					: 'brokers/search-broker'

			const response = await axios.get(
				`/api/${endpoint}?${searchTerm ? `query=${searchTerm}&` : ''}${
					currentFilters.status ? `status=${currentFilters.status}&` : ''
				}${currentFilters.minAge ? `minAge=${currentFilters.minAge}&` : ''}${
					currentFilters.maxAge ? `maxAge=${currentFilters.maxAge}&` : ''
				}page=${page}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)

			const items =
				activeTab === 'carriers'
					? response.data.carriers
					: response.data.brokers
			setSearchResults(items || [])
			setCurrentPage(response?.data?.currentPage || 1)
			setHasMore(response?.data?.hasMore || false)
		} catch (err) {
			if (err.response?.status === 401) {
				localStorage.removeItem('jwtToken')
				window.location.href = '/login'
				return
			}
			setError('An error occurred while searching.')
			console.error('Search error:', err)
		} finally {
			setIsLoading(false)
		}
	}

	const handleSearch = (page = 1) => {
		handleSearchWithFilters(page)
	}

	useEffect(() => {
		handleSearch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab])

	const handleEmailAccessRequest = async provider => {
		try {
			let token = localStorage.getItem('jwtToken')
			token = token?.includes('Bearer') ? token : `Bearer ${token}`

			const response = await axios.post(
				'/api/user/request-email-access',
				{
					provider,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)

			if (response.data.authUrl) {
				window.location.href = response.data.authUrl
			} else {
				console.log('Custom email setup or other provider selected')
			}
		} catch (error) {
			console.error('Error requesting email access:', error)
		}
	}

	useEffect(() => {
		const checkEmailPermissions = async () => {
			try {
				let token = localStorage.getItem('jwtToken')
				token = token?.includes('Bearer') ? token : `Bearer ${token}`

				const response = await axios.get('/api/user/email-permissions', {
					headers: {
						Authorization: token,
					},
				})

				if (!response.data.hasEmailAccess) {
					setShowEmailAccessModal(true)
				}
			} catch (error) {
				console.error('Error checking email permissions:', error)
				setError('An error occurred while checking email permissions.')
				if (error.response.status === 400) {
					console.error('Error status:', error.response.status)
					localStorage.removeItem('jwtToken')
					window.location.href = '/login'
				}
			}
		}

		checkEmailPermissions()
	}, [])

	const handleStatusToggle = () => {
		const newStatus = activeStatusFilter === 'active' ? null : 'active'
		setActiveStatusFilter(newStatus)

		// Update both broker and carrier filters
		setBrokerFilters(prev => ({ ...prev, status: newStatus }))
		setCarrierFilters(prev => ({ ...prev, status: newStatus }))

		// Trigger search with updated filters
		if (activeTab === 'brokers') {
			handleSearchWithFilters(1, { ...brokerFilters, status: newStatus })
		} else {
			handleSearchWithFilters(1, { ...carrierFilters, status: newStatus })
		}
	}

	return (
		<div className="min-h-screen bg-[#15202b]">
			<AuthNavBar togglePanel={togglePanel} />

			<div className="border-b border-gray-700"></div>

			<SidePanel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />

			{activeTab === 'carriers' ? (
				<CarrierPanel
					isOpen={isDetailPanelOpen}
					onClose={() => setIsDetailPanelOpen(false)}
					carrier={selectedItem}
				/>
			) : (
				<BrokerPanel
					isOpen={isDetailPanelOpen}
					onClose={() => setIsDetailPanelOpen(false)}
					broker={selectedItem}
				/>
			)}

			<main className="pl-0 transition-all duration-300 ease-in-out">
				<div className="max-w-[90rem] mx-auto py-4 px-4">
					<div className="max-w-5xl mx-auto flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-2 mb-8">
						{/* Tab Switch */}
						<div className="flex items-center bg-gray-800 rounded-full p-1 w-full sm:w-auto">
							<button
								onClick={() => setActiveTab('brokers')}
								className={`flex-1 sm:flex-none px-6 sm:px-8 py-3 sm:py-4 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 ${
									activeTab === 'brokers'
										? 'bg-[#1d9bf0]/15 text-white ring-2 ring-[#1d9bf0]/30 shadow-[0_0_8px_rgba(29,155,240,0.15)]'
										: 'text-gray-400 hover:text-gray-200'
								}`}
							>
								Brokers
							</button>
							<button
								onClick={() => setActiveTab('carriers')}
								className={`flex-1 sm:flex-none px-6 sm:px-8 py-3 sm:py-4 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 ${
									activeTab === 'carriers'
										? 'bg-[#1d9bf0]/15 text-white ring-2 ring-[#1d9bf0]/30 shadow-[0_0_8px_rgba(29,155,240,0.15)]'
										: 'text-gray-400 hover:text-gray-200'
								}`}
							>
								Carriers
							</button>
						</div>

						<div className="w-full">
							<form
								onSubmit={e => {
									e.preventDefault()
									handleSearch(1)
								}}
								className="flex flex-row items-stretch gap-2"
							>
								<div className="flex flex-row items-center gap-2 w-full">
									<div className="flex-shrink-0">
										{activeTab === 'brokers' ? (
											<BrokerFilters
												filters={brokerFilters}
												onFilterChange={handleFiltersChange}
											/>
										) : (
											<CarrierFilters
												filters={carrierFilters}
												onFilterChange={handleFiltersChange}
											/>
										)}
									</div>
									<div className="relative flex-1 min-w-0 flex items-center">
										<div className="flex w-full focus-within:ring-2 focus-within:ring-[#1d9bf0] rounded-full">
											<input
												type="text"
												value={searchTerm}
												onChange={e => setSearchTerm(e.target.value)}
												placeholder="Search by name, MC number, or DOT number"
												className="shadow-xl pl-4 sm:pl-6 pr-16 sm:pr-24 py-3 sm:py-4 rounded-full focus:outline-none bg-gray-800 text-white flex-1 border-2 border-gray-700 text-sm sm:text-base w-full"
											/>
											<button
												type="submit"
												className="absolute right-2 sm:right-3 top-1/2 -translate-y-1/2 bg-[#1d9bf0] text-white hover:bg-[#1a8cd8] focus:bg-[#1a8cd8] px-3 sm:px-4 py-2 sm:py-2.5 rounded-full transition-all duration-150 ease-in-out flex items-center justify-center hover:scale-105 active:scale-95 shadow-lg hover:shadow-[#1d9bf0]/20"
											>
												<MagnifyingGlassIcon className="w-5 h-5 sm:w-6 sm:h-6" />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					{/* Filter Breadcrumbs */}
					<div className="max-w-5xl mx-auto -mt-4 mb-4 flex items-center justify-between">
						<FilterBreadcrumbs
							filters={
								activeTab === 'brokers'
									? { ...brokerFilters, status: undefined }
									: { ...carrierFilters, status: undefined }
							}
							onRemoveFilter={handleRemoveFilter}
						/>
						<div className="flex items-center gap-3 pr-2">
							<span className="text-sm font-medium text-gray-200">
								Active Only
							</span>
							<button
								role="switch"
								aria-checked={activeStatusFilter === 'active'}
								onClick={handleStatusToggle}
								className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#1d9bf0] focus:ring-offset-2 focus:ring-offset-[#15202b] ${
									activeStatusFilter === 'active'
										? 'bg-[#1d9bf0]'
										: 'bg-gray-600'
								}`}
							>
								<span
									className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out ${
										activeStatusFilter === 'active'
											? 'translate-x-5'
											: 'translate-x-0'
									}`}
								/>
							</button>
						</div>
					</div>

					{isLoading && <SearchResultSkeleton />}
					{error && (
						<p className="text-red-500 text-center text-xl font-bold mt-32">
							{error}
						</p>
					)}

					{searched && !isLoading && !error && (
						<div className="space-y-4 max-w-5xl mx-auto min-h-[calc(100vh-200px)] flex flex-col justify-between">
							{searchResults.length > 0 ? (
								<>
									<div className="flex-1">
										{searchResults.map(item =>
											activeTab === 'carriers' ? (
												<Carrier
													key={item._id}
													carrier={item}
													openCarrierPanel={openDetailPanel}
													searchTerm={searchTerm}
												/>
											) : (
												<Broker
													key={item._id}
													broker={item}
													openBrokerPanel={openDetailPanel}
													searchTerm={searchTerm}
												/>
											)
										)}
									</div>
									<div className="flex justify-center">
										<Pagination
											currentPage={currentPage}
											hasMore={hasMore}
											handleSearch={handleSearch}
										/>
									</div>
								</>
							) : (
								<p className="text-gray-200 text-center text-xl font-bold mt-32">
									No results found for your search query
								</p>
							)}
						</div>
					)}
				</div>
			</main>

			{/* <EmailAccessModal
				isOpen={showEmailAccessModal}
				onClose={() => setShowEmailAccessModal(false)}
				onAuthorize={handleEmailAccessRequest}
			/> */}
		</div>
	)
}

export default DashboardMain
