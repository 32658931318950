import React, { useState, useRef, useEffect } from 'react'
import {
	AdjustmentsHorizontalIcon,
	ChevronDownIcon,
} from '@heroicons/react/24/outline'

const statusOptions = [
	{ value: '', label: 'Any' },
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' },
]

const equipmentOptions = [
	{ value: '', label: 'Any' },
	{ value: 'power_only', label: 'Power Only' },
	{ value: 'dry_van', label: 'Dry Van' },
	{ value: 'reefer', label: 'Reefer' },
	{ value: 'flatbed', label: 'Flatbed' },
	{ value: 'step_deck', label: 'Step Deck' },
]

const safetyRatingOptions = [
	{ value: '', label: 'Any' },
	{ value: 'satisfactory', label: 'Satisfactory' },
	{ value: 'conditional', label: 'Conditional' },
	{ value: 'unsatisfactory', label: 'Unsatisfactory' },
]

const CarrierFilters = ({ filters, onFilterChange }) => {
	const [isOpen, setIsOpen] = useState(false)
	const dropdownRef = useRef(null)
	const [localAge, setLocalAge] = useState({
		minAge: filters.minAge,
		maxAge: filters.maxAge,
	})

	// Update local age when filters change (e.g., when removed via breadcrumb)
	useEffect(() => {
		setLocalAge({
			minAge: filters.minAge,
			maxAge: filters.maxAge,
		})
	}, [filters.minAge, filters.maxAge])

	useEffect(() => {
		const handleClickOutside = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				// Apply age changes before closing
				if (
					localAge.minAge !== filters.minAge ||
					localAge.maxAge !== filters.maxAge
				) {
					onFilterChange?.({
						...filters,
						minAge: localAge.minAge,
						maxAge: localAge.maxAge,
					})
				}
				setIsOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [dropdownRef, localAge, filters, onFilterChange])

	const handleStatusChange = e => {
		onFilterChange?.({ ...filters, status: e.target.value || '' })
	}

	const handleEquipmentChange = e => {
		onFilterChange?.({ ...filters, equipmentType: e.target.value || '' })
	}

	const handleSafetyRatingChange = e => {
		onFilterChange?.({ ...filters, safetyRating: e.target.value || '' })
	}

	const handleHazmatChange = e => {
		onFilterChange?.({
			...filters,
			hazmatCertified: e.target.checked ? 'yes' : '',
		})
	}

	const handleAgeInputChange = (field, value) => {
		setLocalAge(prev => ({
			...prev,
			[field]: value,
		}))
	}

	const handleAgeBlur = field => {
		if (localAge[field] !== filters[field]) {
			onFilterChange?.({
				...filters,
				[field]: localAge[field],
			})
		}
	}

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={e => {
					e.preventDefault()
					if (isOpen) {
						// Apply age changes before closing via button click
						if (
							localAge.minAge !== filters.minAge ||
							localAge.maxAge !== filters.maxAge
						) {
							onFilterChange?.({
								...filters,
								minAge: localAge.minAge,
								maxAge: localAge.maxAge,
							})
						}
					}
					setIsOpen(!isOpen)
				}}
				type="button"
				className="h-[54px] px-2 touch-manipulation"
				aria-label="Filter settings"
			>
				<AdjustmentsHorizontalIcon className="w-9 h-9 hover:text-gray-500 text-gray-300" />
			</button>

			{isOpen && (
				<div className="absolute left-0 mt-2 w-screen sm:w-[600px] max-w-[calc(100vw-2rem)] rounded-lg bg-[#15202b] shadow-[0_0_15px_rgba(59,130,246,0.15)] ring-1 ring-white/10 z-50">
					<div className="p-4">
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
							{/* <div>
								<label className="block text-sm font-medium text-gray-300 mb-2">
									Status
								</label>
								<div className="inline-block relative w-full">
									<select
										id="status"
										value={filters.status}
										onChange={handleStatusChange}
										className="block w-full appearance-none rounded-lg bg-[#263340] py-2.5 pl-3 pr-8 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									>
										{statusOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
										<ChevronDownIcon
											className="h-4 w-4 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
							</div> */}

							{/* <div>
								<label className="block text-sm font-medium text-gray-300 mb-2">
									Equipment Type
								</label>
								<div className="inline-block relative">
									<select
										id="equipment"
										value={filters.equipmentType}
										onChange={handleEquipmentChange}
										className="block w-full appearance-none rounded-lg bg-[#263340] py-2.5 pl-3 pr-8 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									>
										{equipmentOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
										<ChevronDownIcon
											className="h-4 w-4 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
							</div> */}

							{/* <div>
								<label className="block text-sm font-medium text-gray-300 mb-2">
									Safety Rating
								</label>
								<div className="inline-block relative">
									<select
										id="safetyRating"
										value={filters.safetyRating}
										onChange={handleSafetyRatingChange}
										className="block w-full appearance-none rounded-lg bg-[#263340] py-2.5 pl-3 pr-8 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									>
										{safetyRatingOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
										<ChevronDownIcon
											className="h-4 w-4 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
							</div> */}

							<div>
								<label className="block text-sm font-medium text-gray-300 mb-2">
									MC Authority Age (years)
								</label>
								<div className="flex items-center gap-2">
									<input
										type="number"
										min="0"
										max="100"
										value={localAge.minAge}
										onChange={e =>
											handleAgeInputChange('minAge', e.target.value)
										}
										onBlur={() => handleAgeBlur('minAge')}
										placeholder="Min"
										className="block w-[100px] appearance-none rounded-lg bg-[#263340] py-2.5 px-3 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									/>
									<span className="text-gray-400">to</span>
									<input
										type="number"
										min="0"
										max="100"
										value={localAge.maxAge}
										onChange={e =>
											handleAgeInputChange('maxAge', e.target.value)
										}
										onBlur={() => handleAgeBlur('maxAge')}
										placeholder="Max"
										className="block w-[100px] appearance-none rounded-lg bg-[#263340] py-2.5 px-3 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									/>
								</div>
							</div>

							{/* <div className="col-span-2">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										checked={filters.hazmatCertified === 'yes'}
										onChange={handleHazmatChange}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#1d9bf0]"></div>
									<span className="ml-3 text-sm font-medium text-gray-300">
										Hazmat Certified
									</span>
								</label>
							</div> */}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default CarrierFilters
