import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SidePanel from '../SidePanel/SidePanel'
import AuthNavBar from '../Dashboard/AuthNavBar'
import { Cog6ToothIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import GeneralSettings from './GeneralSettings'
import EmailSettings from './EmailSettings'

function Settings() {
	const [isPanelOpen, setIsPanelOpen] = useState(false)
	const navigate = useNavigate()
	const { tab } = useParams()

	const togglePanel = () => {
		setIsPanelOpen(!isPanelOpen)
	}

	useEffect(() => {
		if (!tab) {
			navigate('/settings/email')
		}
	}, [tab, navigate])

	const menuItems = [
		// { name: 'general', icon: Cog6ToothIcon, component: GeneralSettings },
		{ name: 'email', icon: EnvelopeIcon, component: EmailSettings },
	]

	const activeSection = tab || 'general'

	const handleTabChange = tabName => {
		navigate(`/settings/${tabName}`)
	}

	const ActiveComponent =
		menuItems.find(item => item.name === activeSection)?.component ||
		GeneralSettings

	return (
		<div className="bg-[#15202b] text-gray-200 min-h-screen">
			<AuthNavBar togglePanel={togglePanel} />
			<SidePanel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="grid grid-cols-1 md:grid-cols-[200px_1fr] gap-6">
					<div className="bg-[#1a2634] p-4 rounded-lg ring-1 ring-white/10 shadow-[0_0_10px_rgba(59,130,246,0.1)]">
						{menuItems.map(item => (
							<div
								key={item.name}
								className={`p-2 cursor-pointer rounded transition-colors ${
									activeSection === item.name
										? 'bg-[#222e40] text-sky-400'
										: 'hover:bg-[#1e2a3a] text-gray-300 hover:text-gray-200'
								} flex items-center`}
								onClick={() => handleTabChange(item.name)}
							>
								<item.icon className="h-5 w-5 mr-2" />
								{item.name.charAt(0).toUpperCase() + item.name.slice(1)}
							</div>
						))}
					</div>
					<div className="bg-[#1a2634] p-6 rounded-lg ring-1 ring-white/10 shadow-[0_0_10px_rgba(59,130,246,0.1)]">
						<ActiveComponent />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Settings
