import React, { useState, useEffect } from 'react'

const SMTP_CONFIGS = {
	custom: {
		label: 'Custom SMTP',
		host: '',
		port: '',
		secure: true,
	},
	outlook: {
		label: 'Outlook',
		host: 'smtp.office365.com',
		port: '587',
		secure: false,
	},
	yahoo: {
		label: 'Yahoo Mail',
		host: 'smtp.mail.yahoo.com',
		port: '465',
		secure: true,
	},
	godaddy: {
		label: 'GoDaddy',
		host: 'smtpout.secureserver.net',
		port: '465',
		secure: true,
	},
}

const ConnectEmailAccountModal = ({ isOpen, onClose, onAuthorize }) => {
	const [showSmtpForm, setShowSmtpForm] = useState(false)
	const [showGmailScopes, setShowGmailScopes] = useState(false)
	const [selectedProvider, setSelectedProvider] = useState('custom')
	const [includeReadAccess, setIncludeReadAccess] = useState(true)
	const [smtpConfig, setSmtpConfig] = useState({
		email: '',
		host: '',
		port: '',
		username: '',
		password: '',
		secure: true,
		provider: 'custom',
	})

	useEffect(() => {
		if (selectedProvider) {
			const config = SMTP_CONFIGS[selectedProvider]
			setSmtpConfig(prev => ({
				...prev,
				host: config.host,
				port: config.port,
				secure: config.secure,
				provider: selectedProvider,
			}))
		}
	}, [selectedProvider])

	const handleClose = () => {
		setShowSmtpForm(false)
		setShowGmailScopes(false)
		setSelectedProvider('custom')
		setSmtpConfig({
			email: '',
			host: '',
			port: '',
			username: '',
			password: '',
			secure: true,
			provider: 'custom',
		})
		onClose()
	}

	const handleGmailAuthorize = () => {
		onAuthorize('gmail', { includeReadAccess })
	}

	if (!isOpen) return null

	const handleSmtpSubmit = e => {
		e.preventDefault()
		onAuthorize('smtp', smtpConfig)
		setSmtpConfig({
			email: '',
			host: '',
			port: '',
			username: '',
			password: '',
			secure: true,
			provider: 'custom',
		})
		setSelectedProvider('custom')
		setShowSmtpForm(false)
	}

	return (
		<div className="relative z-10">
			<div className="fixed inset-0 bg-black transition-opacity duration-300 opacity-50" />

			<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div className="flex min-h-full items-center justify-center -mt-32">
					<div
						className={`relative transform overflow-hidden rounded-lg bg-[#15202b] text-left shadow-[0_0_15px_rgba(59,130,246,0.1)] ring-1 ring-white/10 transition-all sm:my-8 ${
							showSmtpForm ? 'w-[80rem]' : 'w-[48rem]'
						}`}
					>
						<button
							onClick={handleClose}
							className="absolute right-6 top-6 text-gray-400 hover:text-gray-300"
						>
							<svg
								className="w-4 h-4"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>

						<div className="px-16 pt-12 pb-12">
							<div className="w-full">
								{!showSmtpForm ? (
									<>
										{!showGmailScopes ? (
											<>
												<h3 className="text-3xl font-bold text-white mb-8">
													Connect Email Account
												</h3>
												<p className="text-gray-300 mb-12 text-xl">
													Choose how you want to connect your email account:
												</p>
												<div className="flex flex-col space-y-4 px-12">
													<button
														onClick={() => setShowGmailScopes(true)}
														className="inline-flex w-full items-center justify-center rounded-lg bg-[#1d9bf0] px-8 py-6 text-xl font-semibold text-white shadow-sm hover:bg-[#1a8cd8] focus:bg-[#1a8cd8] transition-colors duration-150 ease-in-out"
													>
														<svg
															className="w-8 h-8 mr-4"
															viewBox="0 0 24 24"
															fill="currentColor"
														>
															<path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z" />
														</svg>
														Connect with Gmail
													</button>
												</div>
											</>
										) : (
											<>
												<div className="text-center mb-8">
													<h3 className="text-2xl font-semibold text-white">
														Gmail Permissions
													</h3>
												</div>

												<div className="px-8">
													<div className="rounded-lg">
														<h4 className="text-lg font-medium text-white mb-6">
															Required Access:
														</h4>
														<div className="space-y-5">
															<div className="flex items-start space-x-3">
																<div className="flex-shrink-0 mt-0.5">
																	<input
																		type="checkbox"
																		checked={true}
																		disabled
																		className="w-4 h-4 rounded bg-[#263340] border-[#2c3b4a] text-[#1d9bf0] cursor-not-allowed opacity-60"
																	/>
																</div>
																<div>
																	<label className="text-gray-300 block text-base">
																		Send emails on your behalf
																	</label>
																</div>
															</div>
															<div className="flex items-start space-x-3">
																<div className="flex-shrink-0 mt-0.5">
																	<input
																		type="checkbox"
																		id="readAccess"
																		checked={includeReadAccess}
																		onChange={e =>
																			setIncludeReadAccess(e.target.checked)
																		}
																		className="w-4 h-4 rounded bg-[#263340] border-[#2c3b4a] text-[#1d9bf0]"
																	/>
																</div>
																<div>
																	<label
																		htmlFor="readAccess"
																		className="text-gray-300 block text-base"
																	>
																		Read and process emails
																	</label>
																	<span className="text-gray-500 text-sm mt-1 block">
																		Required for auto-replies and notifications
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="absolute bottom-0 left-0 right-0 px-6 py-4 flex justify-end space-x-3 border-t border-[#2c3b4a]">
													<button
														onClick={handleClose}
														className="px-5 py-2 text-gray-300 hover:text-white transition-colors duration-150"
													>
														Close
													</button>
													<button
														onClick={handleGmailAuthorize}
														className="px-5 py-2 bg-[#1d9bf0] text-white font-medium rounded hover:bg-[#1a8cd8] transition-colors duration-150"
													>
														Continue
													</button>
												</div>
											</>
										)}
									</>
								) : (
									<div className="flex gap-8">
										{/* Left side - Provider list */}
										<div className="w-1/3 bg-[#263340] rounded-lg p-4">
											<h3 className="text-lg font-semibold mb-4 text-white">
												Email Providers
											</h3>
											<div className="space-y-2">
												{Object.entries(SMTP_CONFIGS).map(([key, config]) => (
													<button
														key={key}
														onClick={() => setSelectedProvider(key)}
														className={`w-full text-left px-4 py-3 rounded transition-colors ${
															selectedProvider === key
																? 'bg-[#1d9bf0] text-white'
																: 'bg-[#1a2634] hover:bg-[#1f2937] text-gray-200'
														}`}
													>
														{config.label}
													</button>
												))}
											</div>
										</div>

										{/* Right side - SMTP Form */}
										<div className="w-2/3">
											<form onSubmit={handleSmtpSubmit} className="space-y-4">
												<div>
													<label className="block text-sm font-medium mb-1 text-gray-200">
														Email Address
													</label>
													<input
														type="email"
														value={smtpConfig.email}
														onChange={e =>
															setSmtpConfig({
																...smtpConfig,
																email: e.target.value,
															})
														}
														className="w-full p-2 rounded-lg bg-[#263340] border border-[#2c3b4a] focus:outline-none focus:border-[#1d9bf0] text-white"
														required
													/>
												</div>
												<div>
													<label className="block text-sm font-medium mb-1 text-gray-200">
														SMTP Host
													</label>
													<input
														type="text"
														value={smtpConfig.host}
														onChange={e =>
															setSmtpConfig({
																...smtpConfig,
																host: e.target.value,
															})
														}
														className="w-full p-2 rounded-lg bg-[#263340] border border-[#2c3b4a] focus:outline-none focus:border-[#1d9bf0] text-white"
														required
														readOnly={selectedProvider !== 'custom'}
													/>
												</div>
												<div>
													<label className="block text-sm font-medium mb-1 text-gray-200">
														SMTP Port
													</label>
													<input
														type="text"
														value={smtpConfig.port}
														onChange={e =>
															setSmtpConfig({
																...smtpConfig,
																port: e.target.value,
															})
														}
														className="w-full p-2 rounded-lg bg-[#263340] border border-[#2c3b4a] focus:outline-none focus:border-[#1d9bf0] text-white"
														required
														readOnly={selectedProvider !== 'custom'}
													/>
												</div>
												<div>
													<label className="block text-sm font-medium mb-1 text-gray-200">
														Username
													</label>
													<input
														type="text"
														value={smtpConfig.username}
														onChange={e =>
															setSmtpConfig({
																...smtpConfig,
																username: e.target.value,
															})
														}
														className="w-full p-2 rounded-lg bg-[#263340] border border-[#2c3b4a] focus:outline-none focus:border-[#1d9bf0] text-white"
														required
													/>
												</div>
												<div>
													<label className="block text-sm font-medium mb-1 text-gray-200">
														Password
													</label>
													<input
														type="password"
														value={smtpConfig.password}
														onChange={e =>
															setSmtpConfig({
																...smtpConfig,
																password: e.target.value,
															})
														}
														className="w-full p-2 rounded-lg bg-[#263340] border border-[#2c3b4a] focus:outline-none focus:border-[#1d9bf0] text-white"
														required
													/>
												</div>

												<div className="flex items-center space-x-2">
													<input
														type="checkbox"
														checked={smtpConfig.secure}
														onChange={e =>
															setSmtpConfig({
																...smtpConfig,
																secure: e.target.checked,
															})
														}
														className="rounded bg-[#263340] border-[#2c3b4a] text-[#1d9bf0]"
														disabled={selectedProvider !== 'custom'}
													/>
													<label className="text-sm text-gray-200">
														Use SSL/TLS
													</label>
												</div>
											</form>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="px-8 py-4 sm:flex sm:flex-row-reverse sm:px-8">
							{showSmtpForm ? (
								<>
									<button
										type="button"
										onClick={handleSmtpSubmit}
										className="inline-flex justify-center rounded-lg bg-[#1d9bf0] px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#1a8cd8] focus:bg-[#1a8cd8] transition-colors duration-150 ease-in-out sm:ml-3"
									>
										Connect
									</button>
									<button
										type="button"
										onClick={() => setShowSmtpForm(false)}
										className="mt-3 inline-flex justify-center rounded-lg bg-[#263340] px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#2c3b4a] transition-colors duration-150 ease-in-out sm:mt-0"
									>
										Back
									</button>
								</>
							) : (
								<button
									type="button"
									onClick={handleClose}
									className="inline-flex justify-center rounded-lg bg-[#263340] px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#2c3b4a] transition-colors duration-150 ease-in-out"
								>
									Close
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConnectEmailAccountModal
