import React from 'react'

function SearchResultSkeleton({ count = 12 }) {
	return (
		<div className="space-y-4 max-w-5xl mx-auto">
			{[...Array(count)].map((_, i) => (
				<div
					key={i}
					className="bg-[#263340] rounded-lg shadow-md p-4 mb-3 animate-pulse"
				>
					<div className="flex flex-col gap-2">
						{/* Top row skeleton */}
						<div className="flex items-center gap-3 pr-24">
							<div className="h-6 w-64 bg-gray-700/50 rounded"></div>
							<div className="h-[22px] w-24 bg-gray-700/50 rounded-full"></div>
							<div className="h-5 w-40 bg-gray-700/50 rounded"></div>
						</div>

						{/* Bottom row skeleton */}
						<div className="flex items-center gap-4 text-sm pr-24">
							<div className="h-[22px] w-16 bg-gray-700/50 rounded-full"></div>
							<div className="flex items-center gap-1">
								<div className="h-4 w-4 bg-gray-700/50 rounded"></div>
								<div className="h-4 w-32 bg-gray-700/50 rounded"></div>
							</div>
							<div className="h-4 w-28 bg-gray-700/50 rounded"></div>
							<div className="h-4 w-28 bg-gray-700/50 rounded"></div>
							<div className="h-4 w-24 bg-gray-700/50 rounded"></div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default SearchResultSkeleton
