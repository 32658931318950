import React from 'react'
import { MapPinIcon } from '@heroicons/react/24/outline'

function Carrier({ carrier, openCarrierPanel, searchTerm }) {
	const capitalize = str => {
		return str
			?.toLowerCase()
			?.split(' ')
			?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			?.join(' ')
	}

	const highlightText = (text, highlight) => {
		if (!highlight.trim() || !text) {
			return <span>{text}</span>
		}

		// Escape special regex characters
		const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		const regex = new RegExp(`(${escapedHighlight})`, 'gi')
		const parts = text.toString().split(regex)
		return (
			<span>
				{parts.filter(String).map((part, i) => {
					return regex.test(part) ? (
						<span key={i} className="bg-[#1d9bf0]/15 text-blue-200 font-medium">
							{part}
						</span>
					) : (
						<span key={i}>{part}</span>
					)
				})}
			</span>
		)
	}

	const truncate = (text, maxLength = 15) => {
		return text?.length > maxLength ? text?.substr(0, maxLength) + '...' : text
	}

	const getCompanyTypeLabel = type => {
		console.log('type', type)

		switch (type) {
			case 'C':
				return 'Carrier'
			case 'CB':
				return 'Carrier Broker'
			default:
				return null
		}
	}

	return (
		<div
			key={carrier._id}
			className="bg-[#263340] rounded-lg shadow-md p-4 mb-3 cursor-pointer relative"
			onClick={() => openCarrierPanel(carrier)}
		>
			<div className="flex flex-col gap-2">
				{/* Top row: Company names */}
				<div className="flex items-center gap-3 pr-24">
					<span className="text-md font-bold text-gray-200">
						{highlightText(truncate(carrier.companyName, 45), searchTerm)}
					</span>
					<div className="flex items-center">
						{getCompanyTypeLabel(carrier.type) && (
							<span className="px-3 py-1 text-xs font-medium bg-gray-500/20 text-gray-300 rounded-full flex items-center gap-1">
								<span
									className={`w-2 h-2 rounded-full relative group ${
										carrier.isActive ? 'bg-green-500' : 'bg-red-500'
									}`}
								>
									<span className="invisible group-hover:visible absolute z-10 -top-8 left-1/2 -translate-x-1/2 px-2 py-1 text-xs bg-gray-800 text-gray-200 rounded shadow-lg whitespace-nowrap">
										{carrier.isActive ? 'Active' : 'Inactive'}
									</span>
								</span>
								{getCompanyTypeLabel(carrier.type)}
							</span>
						)}
					</div>
					{carrier.dba && (
						<span className="text-gray-400 text-sm">
							DBA: {highlightText(carrier.dba, searchTerm)}
						</span>
					)}
				</div>

				{/* Bottom row: Location, Numbers */}
				<div className="flex items-center gap-4 text-sm pr-24">
					<div className="flex items-center gap-1 text-gray-400">
						<MapPinIcon className="w-4 h-4" />
						<span>
							{carrier.address
								? `${capitalize(carrier.address.city)}, ${
										carrier.address.state
								  }`
								: ''}
						</span>
					</div>

					<span className="text-gray-400">
						MC# {highlightText(carrier.mcNumber, searchTerm)}
					</span>
					<span className="text-gray-400">
						DOT# {highlightText(carrier.dotNumber, searchTerm)}
					</span>

					{carrier.currentAuthority?.effectiveDate && (
						<span className="text-gray-400">
							MC Age:{' '}
							{(() => {
								const date = new Date(carrier.currentAuthority.effectiveDate)
								const now = new Date()
								const diffTime = Math.abs(now - date)
								const diffMonths = Math.ceil(
									diffTime / (1000 * 60 * 60 * 24 * 30.44)
								)
								const years = Math.floor(diffMonths / 12)
								const months = diffMonths % 12
								return years === 0 ? `${diffMonths}mo` : `${years}y ${months}mo`
							})()}
						</span>
					)}
				</div>
			</div>
		</div>
	)
}

export default Carrier
