import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

function SuccessMessageHandler() {
	const [show, setShow] = useState(false)
	const location = useLocation()

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		const emailSetup = urlParams.get('emailSetup')

		if (emailSetup === 'success') {
			setShow(true)
			window.history.replaceState({}, document.title, location.pathname)
		}
	}, [location])

	useEffect(() => {
		if (show) {
			const timer = setTimeout(() => {
				setShow(false)
			}, 10000)

			return () => clearTimeout(timer)
		}
	}, [show])

	return (
		<div
			aria-live="assertive"
			className="pointer-events-none fixed inset-0 flex items-start justify-center pt-6 z-50"
		>
			<div className="flex w-full flex-col items-center">
				<Transition
					show={show}
					enter="transform ease-out duration-300 transition"
					enterFrom="translate-y-2 opacity-0"
					enterTo="translate-y-0 opacity-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-[#15202b] ring-1 ring-white/10 shadow-lg">
						<div className="p-4">
							<div className="flex items-start">
								<div className="shrink-0">
									<CheckCircleIcon
										aria-hidden="true"
										className="h-6 w-6 text-green-400"
									/>
								</div>
								<div className="ml-3 w-0 flex-1 pt-0.5">
									<p className="text-sm font-medium text-white">
										Email permission granted successfully!
									</p>
								</div>
								<div className="ml-4 flex shrink-0">
									<button
										type="button"
										onClick={() => setShow(false)}
										className="inline-flex rounded-md bg-[#15202b] text-gray-400 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									>
										<span className="sr-only">Close</span>
										<XMarkIcon aria-hidden="true" className="h-5 w-5" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	)
}

export default SuccessMessageHandler
