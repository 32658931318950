import React, { useState } from 'react'

const FAQItem = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className="border-b border-[#1d3f58]">
			<button
				className="flex justify-between items-center w-full py-4 text-left"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span className="text-lg font-semibold text-[#eef3f9]">{question}</span>
				<span className="text-xl text-[#eef3f9]">{isOpen ? '-' : '+'}</span>
			</button>
			{isOpen && (
				<div className="pb-4 text-[#b3cde4]">
					<p className="text-md font-semibold">{answer}</p>
				</div>
			)}
		</div>
	)
}

const FAQ = () => {
	const faqData = [
		{
			question: 'Which loadboards does LoadLine support?',
			answer:
				'Currently, LoadLine supports DAT One. We are working on adding support for other loadboards in the future.',
		},
		{
			question: 'What types of payment do you accept?',
			answer:
				'We accept payments through Stripe, which supports a wide range of global card networks.',
		},
		{
			question: 'What do you need to sign up?',
			answer: 'An active email address. That’s it!',
		},
		{
			question: 'How does your pricing work? Are there any hidden fees?',
			answer: 'Our pricing model is transparent with no hidden fees.',
		},
		{
			question: 'How often do you release new features or updates?',
			answer:
				'We consistently roll out new features and updates to enhance your experience.',
		},
		{
			question:
				'Is there a free trial available, and do I need a credit card to sign up?',
			answer:
				'Yes, we offer a 7-day free trial, which is available once per company. No credit card is required to sign up for the free trial.',
		},
		{
			question: 'How is LoadLine better than other solutions?',
			answer:
				'LoadLine offers unique features tailored specifically for DAT One users, providing a seamless and efficient workflow that other solutions may not offer.',
		},
	]

	return (
		<div className="bg-[#001b2e]">
			<div className="container mx-auto px-4 sm:px-6 lg:px-8">
				<div className="bg-[#001b2e] py-16">
					<div className="container mx-auto px-4">
						<div className="flex flex-col md:flex-row">
							<div className="md:w-1/2 pr-8 mb-8 md:mb-0">
								<h2 className="text-3xl font-bold text-[#eef3f9] top-8">
									Frequently Asked Questions
								</h2>

								<p className="mt-8 text-[#b3cde4]">
									Have another question?
									<a
										href="https://t.me/loadfetcher_support"
										className="text-blue-400 hover:underline ml-1"
										target="_blank"
										rel="noopener noreferrer"
									>
										Contact Us
									</a>
									.
								</p>
							</div>
							<div className="md:w-1/2 space-y-4 ">
								{faqData.map((faq, index) => (
									<FAQItem
										key={index}
										question={faq.question}
										answer={faq.answer}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FAQ
