import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

function FilterBreadcrumbs({ filters, onRemoveFilter }) {
	const getFilterLabel = (key, value) => {
		switch (key) {
			case 'status':
				return `Status: ${value === 'active' ? 'Active' : 'Inactive'}`
			case 'minAge':
				return `Min Age: ${value} years`
			case 'maxAge':
				return `Max Age: ${value} years`
			default:
				return `${key}: ${value}`
		}
	}

	const handleRemove = (e, key) => {
		e.preventDefault()
		e.stopPropagation()
		onRemoveFilter(key)
	}

	return (
		<div className="flex flex-wrap gap-2 mt-4">
			{Object.entries(filters).map(
				([key, value]) =>
					value && (
						<div
							key={key}
							className="flex items-center gap-2 px-3 py-1 bg-[#1d9bf0]/15 text-blue-200 rounded-full text-sm"
						>
							<span>{getFilterLabel(key, value)}</span>
							<button
								onClick={e => handleRemove(e, key)}
								className="text-blue-200 hover:text-blue-100 transition-colors"
							>
								<XMarkIcon className="w-4 h-4" />
							</button>
						</div>
					)
			)}
		</div>
	)
}

export default FilterBreadcrumbs
