import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import googleIcon from '../../media/google.svg'

import NavBar from '../LandingPage/NavBar'

function Register() {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [consentChecked, setConsentChecked] = useState(true)

	const location = useLocation()

	const devURI = 'http://localhost:5004'
	const productionURI = ''
	const isLocalhost = window.location.hostname === 'localhost'
	const authUrl = isLocalhost ? devURI : productionURI
	let googleAuthUrl = `${authUrl}/auth/google`

	const handleSubmit = async e => {
		e.preventDefault()

		if (!email.trim()) {
			setErrorMessage('Please enter an email address.')
			return
		}

		// Check if password is empty
		if (!password) {
			setErrorMessage('Please enter a password.')
			return
		}

		// Check if confirm password is empty
		if (!confirmPassword) {
			setErrorMessage('Please confirm your password.')
			return
		}

		// Check if passwords match
		if (password !== confirmPassword) {
			setErrorMessage('Passwords do not match.')
			return
		}

		if (!consentChecked) {
			setErrorMessage(
				'You must consent to our Privacy Policy and Terms and Condition to continue.'
			)
			return
		}

		if (password !== confirmPassword) {
			setErrorMessage('Passwords do not match')
			return
		}

		try {
			const response = await fetch('/api/auth/register', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, password }),
			})

			if (response.ok) {
				const data = await response.json()
				console.log('Registration successful:', data)

				try {
					const loginResponse = await fetch('/api/auth/login', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ email, password }),
					})

					if (loginResponse.ok) {
						const loginData = await loginResponse.json()
						console.log('Login successful:', loginData)
						localStorage.setItem('jwtToken', loginData.token)

						const searchParams = new URLSearchParams(location.search)
						const isFromExtension = searchParams.get('from') === 'extension'

						if (isFromExtension && window.opener) {
							window.opener.postMessage(
								{ type: 'AUTH_SUCCESS', payload: { token: loginData.token } },
								'*'
							)
							window.close()
						} else {
							navigate('/dashboard')
						}
					} else {
						console.error('Login failed after registration')
						navigate('/login')
					}
				} catch (error) {
					console.error('Error during login after registration:', error)
					navigate('/login')
				}
			} else {
				const errorData = await response.json()
				setErrorMessage(errorData.message)
			}
		} catch (error) {
			console.error('Error during registration:', error)
			setErrorMessage('An error occurred during registration.')
		}
	}

	const handleGoogleLogin = () => {
		if (!consentChecked) {
			setErrorMessage(
				'You must consent to our Privacy Policy and Terms and Condition to continue.'
			)
			return
		}

		const w = 500
		const h = 600
		const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
		const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

		const googleWindow = window.open(
			googleAuthUrl,
			'Google Sign-In',
			`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
		)

		window.addEventListener('message', event => {
			console.log('event.data.token', event)

			if (event.data.token) {
				localStorage.setItem('jwtToken', event.data.token)
				localStorage.setItem('email', event.data.email)

				const searchParams = new URLSearchParams(location.search)
				const isFromExtension = searchParams.get('from') === 'extension'

				if (isFromExtension && window.opener) {
					window.opener.postMessage(
						{ type: 'AUTH_SUCCESS', payload: { token: event.data.token } },
						'*'
					)
					window.close()
				} else {
					navigate('/dashboard')
				}

				googleWindow.close()
			}
		})
	}

	useEffect(() => {
		if (errorMessage) {
			const timer = setTimeout(() => {
				setErrorMessage('')
			}, 5000)

			return () => clearTimeout(timer)
		}
	}, [errorMessage])

	useEffect(() => {
		const handlePostMessage = event => {
			const { origin } = event
			const isValidOrigin =
				origin === window.location.origin ||
				origin === devURI ||
				origin === productionURI

			if (!isValidOrigin) {
				console.error('Received message from an untrusted origin:', origin)
				return
			}

			const { token, email, message } = event.data

			if (token && email) {
				localStorage.setItem('jwtToken', token)
				localStorage.setItem('email', email)
			}
		}

		window.addEventListener('message', handlePostMessage)

		return () => {
			window.removeEventListener('message', handlePostMessage)
		}
	}, [])

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const error = searchParams.get('error')
		const message = searchParams.get('message')

		if (error && message) {
			setErrorMessage(message)
		}
	}, [location.search])

	useEffect(() => {
		const handlePostMessage = event => {
			const { origin } = event
			const isValidOrigin =
				origin === window.location.origin ||
				origin === devURI ||
				origin === productionURI

			if (!isValidOrigin) {
				console.error('Received message from an untrusted origin:', origin)
				return
			}

			const { error, message, token, email } = event.data

			if (error) {
				setErrorMessage(message)
			} else if (token && email) {
				localStorage.setItem('jwtToken', token)
				localStorage.setItem('email', email)
				navigate('/dashboard')
			}
		}

		window.addEventListener('message', handlePostMessage)

		return () => {
			window.removeEventListener('message', handlePostMessage)
		}
	}, [])

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const error = searchParams.get('error')
		const message = searchParams.get('message')

		if (error && message) {
			setErrorMessage(message)
		}
	}, [location.search])

	return (
		<>
			<NavBar isNotLandingPage={true} />
			<div className="flex justify-center items-start pt-10 pb-10 min-h-[calc(100vh-64px)]">
				<div className="bg-white p-8 rounded shadow-md w-full max-w-md">
					<h2 className="text-2xl font-semibold mb-6 text-center">
						Create an account
					</h2>

					{errorMessage && (
						<div className="text-red-500 text-center mb-4">{errorMessage}</div>
					)}

					<button
						className="google-button w-full inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
						type="button"
						onClick={handleGoogleLogin}
					>
						<img
							src={googleIcon}
							alt="Google"
							style={{ width: '20px', height: '20px' }}
						/>

						<p className="btn-text">Continue with Google</p>
					</button>

					<form onSubmit={handleSubmit} className="space-y-6">
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700 mt-5"
							>
								Email
							</label>
							<input
								type="email"
								id="email"
								placeholder="name@company.com"
								value={email}
								onChange={e => setEmail(e.target.value)}
								className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password
							</label>
							<input
								type="password"
								id="password"
								value={password}
								onChange={e => setPassword(e.target.value)}
								className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
						<div>
							<label
								htmlFor="confirmPassword"
								className="block text-sm font-medium text-gray-700"
							>
								Confirm password
							</label>
							<input
								type="password"
								id="confirmPassword"
								value={confirmPassword}
								onChange={e => setConfirmPassword(e.target.value)}
								className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>

						<div className="flex items-center mb-4">
							<input
								id="consent"
								type="checkbox"
								checked={consentChecked}
								onChange={e => setConsentChecked(e.target.checked)}
								className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
							/>
							<label
								htmlFor="consent"
								className="ml-2 block text-sm text-gray-900"
							>
								By signing up, you agree to our{' '}
								<a href="/policy" className="text-blue-500 hover:underline">
									Privacy Policy
								</a>{' '}
								and{' '}
								<a href="/terms" className="text-blue-500 hover:underline">
									Terms and Conditions
								</a>
							</label>
						</div>
						<div>
							<button
								type="submit"
								className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								Create an account
							</button>
						</div>
					</form>
					<div className="mt-6 text-sm text-center text-gray-500">
						Already have an account?{' '}
						<a
							href={`/login${location.search}`}
							className="text-blue-500 hover:underline"
						>
							Login here
						</a>
					</div>
				</div>
			</div>
		</>
	)
}

export default Register
