import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MapPinIcon } from '@heroicons/react/24/outline'

function BrokerPanel({ isOpen, onClose, broker }) {
	const [detailedData, setDetailedData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)

	useEffect(() => {
		async function fetchDetailedData() {
			if (!isOpen || !broker?.mcNumber) return

			setIsLoading(true)
			setError(null)
			try {
				const token = localStorage.getItem('jwtToken')
				const response = await axios.get(
					`/api/brokers/v2/search?mc=${broker.mcNumber}`,
					{
						headers: {
							Authorization: token?.includes('Bearer')
								? token
								: `Bearer ${token}`,
						},
					}
				)
				setDetailedData(response.data)
			} catch (err) {
				setError('Failed to load detailed broker information')
				console.error('Error fetching broker details:', err)
			} finally {
				setIsLoading(false)
			}
		}

		fetchDetailedData()
	}, [isOpen, broker?.mcNumber])

	const formatAuthorityAge = date => {
		if (!date) return null
		const authorityDate = new Date(date)
		const today = new Date()
		const diffTime = Math.abs(today - authorityDate)
		const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30.44))

		const years = Math.floor(diffMonths / 12)
		const months = diffMonths % 12

		if (years === 0) {
			return `${months} months`
		} else if (months === 0) {
			return `${years} ${years === 1 ? 'year' : 'years'}`
		} else {
			return `${years} ${years === 1 ? 'year' : 'years'} ${months} ${
				months === 1 ? 'month' : 'months'
			}`
		}
	}

	const getAuthorityAgeStyle = date => {
		if (!date) return {}
		const authorityDate = new Date(date)
		const today = new Date()
		const diffTime = Math.abs(today - authorityDate)
		const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30.44))

		if (diffMonths < 12) {
			return {
				pillClass: 'bg-red-500/20',
				textClass: 'text-red-400',
			}
		} else if (diffMonths < 24) {
			return {
				pillClass: 'bg-yellow-500/20',
				textClass: 'text-yellow-400',
			}
		} else {
			return {
				pillClass: 'bg-green-500/20',
				textClass: 'text-green-400',
			}
		}
	}

	const getBondStyle = amount => {
		if (amount === null || amount === undefined) return {}
		return {
			pillClass: amount < 75 ? 'bg-red-500/20' : 'bg-green-500/20',
			textClass: amount < 75 ? 'text-red-400' : 'text-green-400',
		}
	}

	const getCompanyTypeLabel = type => {
		switch (type) {
			case 'B':
				return 'Broker'
			case 'CB':
				return 'Carrier Broker'
			default:
				return type
		}
	}

	const formatDate = date => {
		if (!date) return 'N/A'
		const d = new Date(date)
		return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
	}

	return (
		<>
			<div
				className={`fixed inset-0 bg-black transition-opacity duration-300 ${
					isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'
				} z-40`}
				onClick={onClose}
			></div>
			<div
				className={`fixed bottom-0 left-1/2 -translate-x-1/2 w-full sm:w-[64rem] h-[95dvh] sm:h-[90vh] md:h-[91vh] lg:h-[92vh] bg-[#15202b] text-white shadow-lg z-50 transition-all duration-500 ease-[cubic-bezier(0.16,1,0.3,1)] will-change-transform transform flex flex-col sm:rounded-t-lg ring-1 ring-white/10 shadow-[0_0_15px_rgba(59,130,246,0.1)] ${
					isOpen
						? 'translate-y-0 scale-100 opacity-100'
						: 'translate-y-full scale-95 opacity-0 pointer-events-none'
				}`}
			>
				<div className="h-12 flex items-center px-4 sm:px-6 flex-shrink-0 justify-between sm:justify-end pt-safe">
					<h2 className="text-lg font-semibold sm:hidden">Broker Details</h2>
					<button
						onClick={onClose}
						className="text-gray-400 hover:text-gray-300 p-2 -mr-2 sm:p-0 sm:-mr-0"
					>
						<svg
							className="w-6 h-6 sm:w-5 sm:h-5"
							fill="none"
							stroke="currentColor"
							strokeWidth="2.5"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18L18 6M6 6l12 12"
							></path>
						</svg>
					</button>
				</div>
				<div className="flex-1 overflow-y-auto pb-safe">
					<div className="px-4 sm:px-12 pt-2">
						{isLoading ? (
							<div className="flex items-center justify-center h-32">
								<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
							</div>
						) : error ? (
							<div className="text-red-400">{error}</div>
						) : detailedData ? (
							<div className="space-y-8">
								<div>
									<div className="flex items-center gap-3 mb-3">
										<h1 className="text-2xl font-bold text-white">
											{detailedData.companyName}
										</h1>
										{detailedData.type && (
											<span className="px-3 py-1 text-xs font-medium bg-blue-500/20 text-blue-300 rounded-full">
												{getCompanyTypeLabel(detailedData.type)}
											</span>
										)}
									</div>
									<div className="flex gap-10 text-gray-400 text-sm">
										{detailedData.mcNumber && (
											<span>MC# {detailedData.mcNumber}</span>
										)}
										{detailedData.dotNumber && (
											<span>DOT# {detailedData.dotNumber}</span>
										)}
										{detailedData.ein && <span>EIN# {detailedData.ein}</span>}
									</div>
									{detailedData.address && (
										<div className="text-gray-300 flex items-center gap-2 mt-6 text-sm">
											<MapPinIcon className="h-4 w-4" />
											<span className="-ml-1">
												{detailedData.address.street},{' '}
												{detailedData.address.city},{' '}
												{detailedData.address.state}{' '}
												{detailedData.address.zipCode}
											</span>
										</div>
									)}
									<hr className="border-gray-800 my-6" />
									<div className="mt-2 flex gap-10 text-md">
										{detailedData.currentAuthority?.effectiveDate && (
											<div className="flex flex-col gap-1">
												<span className="text-gray-200">MC Authority</span>
												<span
													className={`font-medium ${
														getAuthorityAgeStyle(
															detailedData.currentAuthority.effectiveDate
														).textClass
													}`}
												>
													{formatAuthorityAge(
														detailedData.currentAuthority.effectiveDate
													)}
												</span>
											</div>
										)}

										{detailedData?.bondAmount !== null &&
											detailedData?.bondAmount !== undefined && (
												<div className="flex flex-col gap-2">
													<span className="text-gray-200">Bond</span>
													<span
														className={`font-medium ${
															getBondStyle(detailedData.bondAmount).textClass
														}`}
													>
														$
														{(detailedData.bondAmount * 1000)?.toLocaleString()}
													</span>
												</div>
											)}

										{detailedData?.domains?.length > 0 && (
											<div className="flex flex-col gap-2">
												<span className="text-gray-200">Domains</span>
												<div className="flex items-center gap-2">
													{detailedData.domains
														.slice(0, 3)
														.map((domain, index) => (
															<span
																key={index}
																className="text-blue-400 hover:text-blue-300 cursor-pointer"
															>
																@{domain.domainName}
															</span>
														))}
													{detailedData.domains.length > 3 && (
														<span className="text-gray-400">
															+{detailedData.domains.length - 3} more
														</span>
													)}
												</div>
											</div>
										)}
									</div>
									<hr className="border-gray-800 my-6" />
								</div>

								<div className="grid grid-cols-1 gap-6">
									<div className="rounded-lg p-0">
										<h3 className="text-lg font-semibold mb-4 text-gray-300">
											Authority History
										</h3>
										<div className="mt-1 flow-root">
											<div className="overflow-x-auto">
												<div className="inline-block max-w-2xl py-2 align-middle">
													<table className="w-full divide-y divide-gray-700">
														<thead>
															<tr>
																<th
																	scope="col"
																	className="px-2 py-3.5 text-left text-sm font-semibold text-white w-1/3"
																>
																	Authority Type
																</th>
																<th
																	scope="col"
																	className="py-3.5 pr-2 pl-2 text-left text-sm font-semibold text-white sm:pl-0 w-1/2"
																>
																	Original Action
																</th>
																<th
																	scope="col"
																	className="px-2 py-3.5 text-left text-sm font-semibold text-white w-1/3"
																>
																	Disposition
																</th>
															</tr>
														</thead>
														<tbody className="divide-y divide-gray-800">
															{detailedData?.authorityHistory?.map(
																(history, index) => {
																	const isDismissed =
																		history?.disposition?.action?.includes(
																			'DISCONTINUED'
																		)

																	const isActionDateExists =
																		history?.originalAction?.actionDate

																	if (isDismissed || !isActionDateExists)
																		return null

																	return (
																		<tr key={index}>
																			<td className="px-3 py-4 text-sm whitespace-nowrap text-gray-300">
																				{history?.authorityType || 'N/A'}
																			</td>
																			<td className="py-4 pr-1 pl-1 text-sm whitespace-nowrap text-gray-300 sm:pl-0">
																				{history?.originalAction?.action}{' '}
																				{formatDate(
																					history?.originalAction?.actionDate
																				)}
																			</td>
																			<td className="px-3 py-4 text-sm whitespace-nowrap text-gray-300">
																				{history?.disposition?.action}{' '}
																				{history?.disposition?.actionDate &&
																					formatDate(
																						history?.disposition?.actionDate
																					)}
																			</td>
																		</tr>
																	)
																}
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<p>No broker selected</p>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default BrokerPanel
