import React, { useState, useRef, useEffect } from 'react'
import {
	AdjustmentsHorizontalIcon,
	ChevronDownIcon,
} from '@heroicons/react/24/outline'

const statusOptions = [
	{ value: '', label: 'Any' },
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' },
]

const BrokerFilters = ({ filters, onFilterChange }) => {
	const [isOpen, setIsOpen] = useState(false)
	const dropdownRef = useRef(null)
	const [localAge, setLocalAge] = useState({
		minAge: filters.minAge,
		maxAge: filters.maxAge,
	})

	useEffect(() => {
		setLocalAge({
			minAge: filters.minAge,
			maxAge: filters.maxAge,
		})
	}, [filters.minAge, filters.maxAge])

	useEffect(() => {
		const handleClickOutside = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				if (
					localAge.minAge !== filters.minAge ||
					localAge.maxAge !== filters.maxAge
				) {
					onFilterChange?.({
						...filters,
						minAge: localAge.minAge,
						maxAge: localAge.maxAge,
					})
				}
				setIsOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [dropdownRef, localAge, filters, onFilterChange])

	const handleStatusChange = e => {
		onFilterChange?.({ ...filters, status: e.target.value || '' })
	}

	const handleAgeInputChange = (field, value) => {
		setLocalAge(prev => ({
			...prev,
			[field]: value,
		}))
	}

	const handleAgeBlur = field => {
		if (localAge[field] !== filters[field]) {
			onFilterChange?.({
				...filters,
				[field]: localAge[field],
			})
		}
	}

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={e => {
					e.preventDefault()
					if (isOpen) {
						if (
							localAge.minAge !== filters.minAge ||
							localAge.maxAge !== filters.maxAge
						) {
							onFilterChange?.({
								...filters,
								minAge: localAge.minAge,
								maxAge: localAge.maxAge,
							})
						}
					}
					setIsOpen(!isOpen)
				}}
				type="button"
				className="h-[54px] px-2 touch-manipulation"
				aria-label="Filter settings"
			>
				<AdjustmentsHorizontalIcon className="w-9 h-9 hover:text-gray-500 text-gray-300" />
			</button>

			{isOpen && (
				<div className="absolute left-0 mt-2 w-screen sm:w-[600px] max-w-[calc(100vw-2rem)] rounded-lg bg-[#15202b] shadow-[0_0_15px_rgba(59,130,246,0.15)] ring-1 ring-white/10 z-50">
					<div className="p-4">
						<div className="flex flex-col sm:flex-row items-start sm:items-center gap-6">
							{/* <div className="w-full sm:w-auto">
								<label className="block text-sm font-medium text-gray-300 mb-2">
									Status
								</label>
								<div className="inline-block relative w-full sm:w-[160px]">
									<select
										id="status"
										value={filters.status}
										onChange={handleStatusChange}
										className="block w-full appearance-none rounded-lg bg-[#263340] py-2.5 pl-3 pr-8 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									>
										{statusOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
										<ChevronDownIcon
											className="h-4 w-4 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
							</div> */}

							<div>
								<label className="block text-sm font-medium text-gray-300 mb-2">
									MC Authority Age (years)
								</label>
								<div className="flex items-center gap-2">
									<input
										type="number"
										min="0"
										max="100"
										value={localAge.minAge}
										onChange={e =>
											handleAgeInputChange('minAge', e.target.value)
										}
										onBlur={() => handleAgeBlur('minAge')}
										placeholder="Min"
										className="block w-[100px] appearance-none rounded-lg bg-[#263340] py-2.5 px-3 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									/>
									<span className="text-gray-400">to</span>
									<input
										type="number"
										min="0"
										max="100"
										value={localAge.maxAge}
										onChange={e =>
											handleAgeInputChange('maxAge', e.target.value)
										}
										onBlur={() => handleAgeBlur('maxAge')}
										placeholder="Max"
										className="block w-[100px] appearance-none rounded-lg bg-[#263340] py-2.5 px-3 text-sm text-gray-200 outline-1 -outline-offset-1 outline-gray-700 focus:outline-2 focus:-outline-offset-2 focus:outline-[#1d9bf0] border border-gray-700/50"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default BrokerFilters
